@import 'variable';

#root {
  height: 100vh;
}

//=========================================
/********** margin padding function start here **************/
//=========================================
.for(@i, @n) {
  .-each(@i);
}

.for(@n) when (isnumber(@n)) {
  .for(1, @n);
}

.for(@i, @n) when not (@i =@n) {
  .for((@i + (@n - @i) / abs(@n - @i)), @n);
}

//--------  End of for loop  ---------//
//=========================================
//              FOR EACH LOOP             =
//=========================================
.for(@array) when (default()) {
  .for-impl_(length(@array));
}

.for-impl_(@i) when (@i > 1) {
  .for-impl_((@i - 1));
}

.for-impl_(@i) when (@i > 0) {
  .-each(extract(@array, @i));
}

//--------  End of for each loop  ---------//

@values-array: 0, 6, 8, 12, 16, 18, 20, 24, 32, 40, 48;

// init functions
.make-space() {
  .for(@values-array);

  .-each(@value) {
    .p-@{value} {
      padding: ~'@{value}px';
    }

    .pt-@{value} {
      padding-top: ~'@{value}px';
    }

    .pl-@{value} {
      padding-left: ~'@{value}px';
    }

    .pr-@{value} {
      padding-right: ~'@{value}px';
    }

    .pb-@{value} {
      padding-bottom: ~'@{value}px';
    }

    .m-@{value} {
      margin: ~'@{value}px';
    }

    .mt-@{value} {
      margin-top: ~'@{value}px';
    }

    .ml-@{value} {
      margin-left: ~'@{value}px';
    }

    .mr-@{value} {
      margin-right: ~'@{value}px';
    }

    .mb-@{value} {
      margin-bottom: ~'@{value}px';
    }

    .text-@{value}{
      font-size: ~'@{value}px';
    }

    .gap-@{value}{
      gap: ~'@{value}px';
    }
  }
}

.make-space();

//=========================================
/********** margin padding function end here **************/
//=========================================
.m-hide {
  display: block;

  @media @screen767 {
    display: none;
  }
}

.d-hide {
  display: none;

  @media @screen767 {
    display: block;
  }
}

.wrapper {
  padding:16px 24px;
  padding-bottom: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  @media @screen991 {
    font-size: 14px;
    padding: 24px;
  }

  @media @screen767 {
    padding: 16px;
    padding-bottom: 0;
  }
}

.responsive-table {
  width: 100%;
  overflow: auto;
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
  .ant-table-cell {
    white-space: pre-wrap;
    &.ant-table-cell-ellipsis{
      white-space: nowrap;
    }
  };
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.pointer {
  cursor: pointer;
}

.grab {
  cursor: grab;
}

//=========================================
/********** text alignment start here **************/
//=========================================
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

//=========================================
/********** text alignment end here **************/
//=========================================

//=========================================
/********** flex box class start here **************/
//=========================================

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-1 {
  flex: 1;
}

//=========================================
/********** flex box class end here **************/
//=========================================

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

//width classes

.site-result-demo-error-icon {
  color: @colorError;
}

//border style
.b-0 {
  border: none;
}

//border style

/*position class start here*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/*position class end here*/

//CUSTOM STYLE CLASSES END

.header-wrapper {
  .d-flex;
  .justify-between;
  .align-center;
}

.sticky-action-form {
  .ant-card-head-title {
    @media @screen767 {
      padding: 0;
    }
  }

  .ant-page-header-heading {
    @media @screen767 {
      padding: 0;
      min-height: 48px;
    }
  }
}

#logo {
  height: 64px;
  overflow: hidden;
  font-weight: 700;
  font-size: 18px;
  font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji,
    segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
  line-height: 64px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  .d-flex;
  .align-center;
  .justify-center;
  color: @black-light-color;

  img {
    height: 32px;
  }
}

.delete-icon {
  color: @theme-color;
  cursor: pointer;
}

/*table draggable css start here*/
.row-dragging {
  background: @white-light-color;
  border: 1px solid @white-med-color;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.react-tel-input .invalid-number-message {
  left: 0px;
  top: 35px;
  background: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

/*sidebar custom less start here*/

.responsive-logo img {
  width: 44px;
}

.ant-layout-sider.close {
  width: 0;
  max-width: 0;
  min-width: 0;
  left: 0;
}

.ant-layout-sider {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  @media @screen767 {
    height: 100%;
    left: -240px;
    z-index: 12;
    width: 0;
    max-width: 0;
    position: fixed;
    &.dashboard-sidebar {
      position: fixed;
    }
  }
}

.app-header,
.app-main-sidebar .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.ant-layout-header {
  @media @screen767 {
    padding: 0 16px;
  }
}

header.ant-layout-header.site-layout-sub-header-background.app-header {
  height: 64px;
  padding: 0 32px;
  color: @grey;
  line-height: 64px;
  background: @white-color;
  display: none;
  @media @screen767 {
   display: block;
  }
}

.overlay-disable {
  @media @screen767 {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .overlay-responsive.active {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: @black-color;
    opacity: 0.6;
    z-index: 11;
  }
}

/*sidebar custom less end here*/
.ant-layout-footer {
  @media @screen767 {
    padding: 16px 50px;
  }
}

/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

:hover::-webkit-scrollbar-thumb {
  border: 8px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}

/*ant card body scroll class*/
.ant-body-scroll {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: auto;

  .ant-card-head {
    position: relative;
    z-index: 1;
  }

  .ant-page-header-heading {
    min-height: 48px;
  }

  > .ant-card-body {
    flex: 1 1;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .card-body-wrapper {
      height: 100%;
      overflow: auto;
      padding: 24px;
    }
  }
}

.common-ant-form-item {
  &.ant-form-item {
    margin-bottom: 0;
    min-width: 380px;
  }
}

.ant-card-small {
  > .ant-card-body {
    .card-body-wrapper {
      padding: 12px;
    }
  }
}

.ant-card .ant-card-actions > li {
  margin: 12px 24px;
}

.title {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 5px;
  svg {
    margin-right: 5px;
    font-size: 16px;
  }
}

/*antbody scroll class end here*/
/*fleg dropdown css start here*/
.react-tel-input .form-control {
  padding: 4px 14px 4px 60px;
  width: 100%;
  position: relative;
  background-color: @white-color;
  border: 1px solid @off-white;
  border-radius: 6px;
  font-size: 14px;
}

/*fleg dropdown css end here*/



.app-layout-wrapper{
  .ant-menu{
    .ant-menu-item-icon{
      &.anticon{
        font-size: 18px;
      }
    }
  }
  &.ant-layout {

    .ant-layout-sider{
      &.ant-layout-sider-below{

        position: fixed;
      }
    }
  }
}

.custom-filter-dropdown {
  padding: 12px;
  min-width: 180px;
  word-wrap: break-word;

.ant-radio-wrapper {
  margin-bottom: 6px;
  .ant-radio-inner{
    height: 18px;
    width: 18px;
  }
}

  .ok-button {
    width: 69px;
  }
  .reset-button {
    width: 69px;
    margin-right: 9px;
    border-radius: 6px;
    background-color: #f1f3f7;
    border: none;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 10px;
    margin: 3px 0 9px 9px;
  }
  .control-panel{
    margin-top: 16px;
  }
}

.card-body-padding {
  padding: 16px 24px;
  &.inner-card {
    .ant-card-body {
      padding: 16px;
    }
  }
}

.card-container {
  .card-title {
    margin-left: 6px;
  }
  .ant-card-body {
    padding: 10px;
  }
  .ant-tabs-tab {
    height: 28px;
    font-weight: 500;
  }
  .ant-tabs-card .ant-tabs-content {
    font-size: 12px;
    height: calc(100vh - 133px);
    overflow-y: auto;
    margin-top: -16px;
    border-radius: 6px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.02);
  }
  .ant-form-item-explain {
    font-size: 12px;
    margin: 3px 0 9px 0px;
  }
  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 10px;
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  [data-theme='compact'] .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
  }
}

.common-popover {
  width: 130px;
  text-align: center;
  .ant-popover-inner-content {
    padding: unset;
    width: 100%;
  }
}

.notification-dropdown {
   .ant-dropdown-menu {
    width: 412px;
    height: 649px;
    overflow: hidden;
    overflow-y: auto;
  }
}

/* Chrome, Safari, Edge, Opera */
.ant-input::-webkit-outer-spin-button,
.ant-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ant-input[type='number'] {
  -moz-appearance: textfield;
}

.show-on-web-input{
.ant-form-item-row{
  flex-direction: column;
}
.ant-form-item-label{
  text-align: start;
}
}
footer.ant-layout-footer{
  padding: 16px 32px;
}
.ant-table-wrapper{
  height: 94%;
  .ant-table-pagination.ant-pagination
  {
    position: sticky;
    bottom: 0;
    right: 32px;
    margin: 0;
    background: @white-light-color;
    padding: 8px 12px;
    border-top: 1px solid #f0f0f0;
    z-index: 2;
    align-items: center;

  }
  .ant-spin-nested-loading{
    height: 100%;
    .ant-spin-container{
      height: 100%;
      .ant-table{
        height: calc(100% - 50px);
      }
    }
  }

  .ant-table-empty .ant-table-container{
    height: 100%;
    .ant-table-body{
      height: 100%;
      table {
        height: 100%;
      }
    }
  }

}
.ant-form-item .ant-form-item-control-input-content
{
  .ant-picker{
    width: 100%;
  }
}
.dot{
  position: absolute;
  right: 0;
  top: 0;
  height: 6px;
  width: 6px;
}
.dot:before{
  content:' ';
  position: absolute;
  z-index:2;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background-color: #ff4200;
  border-radius: 50%;
}

.dot:after {
  content:' ';
  position: absolute;
  z-index:1;
  width:6px;
  height:6px;
  background-color: #ff4200;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,.3) inset;
  -webkit-animation-name:'ripple';
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
}

@keyframes ripple {
   0% {
    left:5px;
    top:5px;
    opcity:75;
    width:0;
    height:0;
  }
  100% {
    left:-22px;
    top:-22px;
    opacity: 0;
    width:50px;
    height:50px;
  }
}
.ant-layout-sider-children{
  > .ant-menu{
    height: calc(100vh - 64px);
    overflow: auto;
  }
}
.ant-layout .ant-layout-sider-light
{
  min-width: 240px;
}

.ql-disabled{
  background-color: @body-background;
  color: #00000040;
}
.side-bar{
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
   overflow: auto;
   & > .ant-menu{
    overflow: auto;
   }
   .ant-menu-light.ant-menu-root.ant-menu-inline
   {
    border: 0;
   }
   .m-hide{
     & >.ant-menu{
      .ant-menu-item{
        &:last-child{
              margin-inline: 0;
            margin-bottom: 0;
            background: var(--ant-color-primary);
            border-radius: 0;
            width: auto;
          .ant-menu-title-content{
            color: #fff;
            text-align: center;
            display: block;
          }
        }
      }
      & > .ant-menu-submenu{
        .ant-menu-submenu-title{
          display: flex;
          align-items: center;
          .ant-avatar{
            flex-shrink: 0;
          }
          .ant-menu-title-content{
            max-width: 138px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
   }
}
.desktop-notification-drawer{
.notification-list{
  padding-left: 0;
  margin-top: 0;
  .notification-list-item{
    align-items: center;
  }
}
}
.ant-layout-sider-collapsed{
  .side-bar{
     .m-hide{

          .ant-menu-item:last-child
          {
              display: none;
          }
      .ant-menu-submenu-title{
        padding-left: 24px;
      }
     }
  }
}
  .side-bar{
      .m-hide{
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        .ant-menu-item.ant-menu-item-selected
        {
          background: transparent;
          color: var(--ant-menu-item-color);
        }
      }
  }
.collapsed-trigger-action{
    position: absolute;
    right: -10px;
    height: 20px;
    width: 20px;
    top: 20px;
    background: #436fa6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media @screen767 {
    display: none;
    }
}
.notification-list{
  &:not(:last-child){
   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
   padding-bottom: 12px;
   margin-bottom: 12px;
  }
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
  margin: 0;
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-cell, .ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th
{
  font-size: 12px;
}
.ant-table-wrapper{
border: 1px solid rgba(0, 0, 0, 0.12);
border-radius: 8px;
overflow: hidden;

.ant-pagination{
  padding: 0 16px;
}
}
.site-page-header-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 8px;
  h3{
    margin: 0;
  }
  .filter-input
  {
    margin: 0;
    gap: 8px;
    button{
      margin: 0;
    }
  }
  .list-search{
       min-width: 200px;
    width: 200px;
    flex: 1;
  }
}

.ant-table-wrapper{

  .ant-table-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    .ant-table-body{
      flex: 1;
      max-height: none !important;
    }
  }

}

.min-w-fit {
  min-width: fit-content;
}

.error-red {
  color: @error-red;
}

.custom-tags {
  padding: 0px 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-right: 4px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close-button {
    margin-left: 8px;
    cursor: pointer;
    .close-icon{
      font-size: 10px;
    }
  }
}